






































































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import moment from 'moment'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import FileUploader from '@/partials/forms/components/FileUploader.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ProfileUploader from '@/partials/components/ProfileUploader.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import DateInput from '@/partials/forms/components/DateTime/DateSimple.vue'

@Component({
  directives: { mask },
  components: {
    DateInput,
    FileUploader,
    GenericModalDelete,
    ProfileUploader,
    PasswordConfirmField,
    NotifyOnFailed,
    ValidationProvider,
    ValidationObserver
  }
})
export default class MedicalPatientForm extends Mixins(FlashMessageMixin, ValidatorConfigMixin) {
  @Prop({ type: String }) patientId!: string
  @Prop({ type: String }) type!: 'create' | 'edit'

  done = true

  form: Record<string, any> = {
    name: '',
    email: '',
    cpf: '',
    sex: 'm',
    birthday: '2000-01-01 12:00:00',
    picture: '',
    pictures: [
      {
        file: '',
        obtainedAt: moment().format('YYYY-MM-DD')
      }
    ],
    case: 'primary',
    left: false,
    right: false,
    confirm: '',
    errors: {
      name: '',
      email: '',
      cpf: '',
      sex: '',
      birthday: '',
      case: '',
      side: '',
      confirm: ''
    }
  }

  profilePictureURL = ''

  created () {
    if (this.type === 'edit') {
      axios.get(`patient/${this.patientId}`)
        .then(response => {
          const casedResponse = camelCaseKeys(response.data.data, { deep: true })

          Object.assign(this.form, casedResponse)
          this.form.picture = ''
          this.profilePictureURL = casedResponse.picture.publicPath
        })
    }
  }

  submit () {
    this.done = false
    const submitForm = snakeCaseKeys(this.form, { deep: true })
    const request = this.type === 'create' ? axios.post('/patient', submitForm) : axios.put(`/patient/${this.patientId}`, submitForm)

    request
      .then(() => {
        const verb = this.type === 'create' ? 'cadastrado' : 'editado'
        const flashMessage = {
          message: `O Paciente foi ${verb} com sucesso.`,
          kind: 'success'
        }
        this.$router.push({ name: 'MedicalPatients' }, () => this.setFlashMessage(flashMessage))
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  get canUploadFile () {
    return (this.type === 'create' && this.form.pictures.length < 5) || this.type !== 'create'
  }

  addNewPicture () {
    this.form.pictures.push({
      file: '',
      obtainedAt: moment().format('YYYY-MM-DD')
    })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      name: '',
      email: '',
      cpf: '',
      sex: '',
      birthday: '',
      confirm: ''
    }
  }

  deleteModalIsOpened = false

  postDeleteSubmit () {
    this.deleteModalIsOpened = false
    const flashMessage: flashMessage = {
      message: 'O Paciente foi removido com sucesso.',
      type: 'success'
    }
    this.$nextTick(() => {
      this.setFlashMessage(flashMessage)
      this.$router.push({ name: 'MedicalPatients' })
    })
  }

  repeatObtainedAt () {
    this.form.pictures = this.form.pictures.map((picture: any) => {
      return {
        ...picture,
        obtainedAt: this.form.pictures[0].obtainedAt
      }
    })
  }
}
